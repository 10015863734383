import React, { useContext, useEffect } from "react";

import GridItem from "../../components/GridItem";
import GridLines from "../../components/GridLines";
import MenuBar from "../../components/MenuBar";

import { StyledPage } from "./style";
import { useSelectionContext } from "../../contexts/SelectionContext";
import { WebSocketContext } from "../../contexts/WebSocketContext";

const Play: React.FC = () => {
	const { selections } = useSelectionContext();
	const { socket } = useContext(WebSocketContext);

	useEffect(() => {
		if (socket) socket.emit("play");
	}, [socket]);

	return (
  <StyledPage>
    <div className="header">
      <div className="gw">gw 1</div>
      <div className="state">in game</div>
      <div className="help">help</div>
    </div>
    <div className="points-container">
      <div className="title" />
      <div className="points" />
    </div>
    <div className="grid">
      {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(i => (
        <GridItem
          removable={false}
          fixture={selections && selections[i]}
          index={i}
        />
				))}
      <GridLines />
    </div>
    <div className="prompt">LIVE</div>
    <MenuBar />
  </StyledPage>
	);
};

export default Play;
