import React from "react";
import { StyledFixture } from "./style";

interface FixtureProps {
	home?: string;
	away?: string;
	pointsHome?: number;
	pointsAway?: number;
	click(chosen?: string, chosenPoints?: number): void;
}

const Fixture: React.FC<FixtureProps> = ({
	home,
	away,
	pointsHome,
	pointsAway,
	click
}) => {
	return (
		<StyledFixture className="fixture">
			<div
				tabIndex={0}
				role="button"
				className="side away"
				onClick={() => click(away, pointsAway)}
				onKeyDown={e => {
					if (e.key === "Enter") click(away, pointsAway);
				}}
			>
				<img
					src={
						process.env.REACT_APP_GAMETYPE === "soccer"
							? `/images/team-logos-soccer/${away}.svg`
							: `/images/team-logos/${away}.svg`
					}
					alt={away}
				/>
				<div className="data-container">
					<div className="team-name">{away}</div>
					<div className="points" />
				</div>
			</div>
			<div className="at">
				{process.env.REACT_APP_GAMETYPE === "soccer" ? "vs" : "at"}
			</div>
			<div
				tabIndex={0}
				role="button"
				className="side home"
				onClick={() => click(home, pointsHome)}
				onKeyDown={e => {
					if (e.key === "Enter") click(home, pointsHome);
				}}
			>
				<div className="data-container">
					<div className="team-name">{home}</div>
					<div className="points" />
				</div>
				<img
					src={
						process.env.REACT_APP_GAMETYPE === "soccer"
							? `/images/team-logos-soccer/${home}.svg`
							: `/images/team-logos/${home}.svg`
					}
					alt={home}
				/>
			</div>
		</StyledFixture>
	);
};

export default Fixture;
