import styled from "styled-components";

export const StyledFixture = styled.div`
	position: relative;
	color: white;
	background: #091e7c;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
	-webkit-box-shadow: inset 0px -3px 10px 0px rgba(15, 75, 255, 0.91);
	-moz-box-shadow: inset 0px -3px 10px 0px rgba(15, 75, 255, 0.91);
	box-shadow: inset 0px -3px 10px 0px rgba(15, 75, 255, 0.91);

	.side {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 7px;
		font-weight: 900;
		font-style: oblique;
		font-size: 1.3rem;
		width: 165px;

		.data-container {
			transform: skew(-10deg);
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.points {
				width: 5px;
				font-size: 1.5rem;
				line-height: 15px;

				.smaller {
					font-size: 0.9rem;
				}
			}
		}

		img {
			height: 85px;
			width: 85px;
		}
	}

	.at {
		position: relative;
		z-index: 1;
		background: white;
		border-radius: 50%;
		color: black;
		height: 20px;
		width: 25px;
		text-align: center;
	}
`;
