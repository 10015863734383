import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const StyledPage = styled.div`
	position: relative;
	height: 100vh;
	max-height: 100vh;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background: rgba(1, 19, 187, 1);
	background: -moz-radial-gradient(
		bottom,
		ellipse cover,
		rgba(1, 19, 187, 1) 0%,
		rgba(4, 16, 62, 1) 53%,
		rgba(5, 15, 36, 1) 64%
	);
	background: -webkit-gradient(
		radial,
		bottom center,
		0px,
		bottom center,
		100%,
		color-stop(0%, rgba(1, 19, 187, 1)),
		color-stop(53%, rgba(4, 16, 62, 1)),
		color-stop(64%, rgba(5, 15, 36, 1))
	);
	background: -webkit-radial-gradient(
		bottom,
		ellipse cover,
		rgba(1, 19, 187, 1) 0%,
		rgba(4, 16, 62, 1) 53%,
		rgba(5, 15, 36, 1) 64%
	);
	background: -o-radial-gradient(
		bottom,
		ellipse cover,
		rgba(1, 19, 187, 1) 0%,
		rgba(4, 16, 62, 1) 53%,
		rgba(5, 15, 36, 1) 64%
	);
	background: -ms-radial-gradient(
		bottom,
		ellipse cover,
		rgba(1, 19, 187, 1) 0%,
		rgba(4, 16, 62, 1) 53%,
		rgba(5, 15, 36, 1) 64%
	);
	background: radial-gradient(
		ellipse at bottom,
		rgba(1, 19, 187, 1) 0%,
		rgba(4, 16, 62, 1) 53%,
		rgba(5, 15, 36, 1) 64%
	);
	color: white;
	align-items: center;

	.header {
		display: flex;
		width: 100%;
		padding: 30px 0 20px 0;
		font-size: 1.5rem;
		font-weight: 600;

		p {
			flex-grow: 1;
			text-align: center;
		}

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			height: 30px;
			width: 30px;
			background: #083196;
			margin-left: -40px;
			margin-right: 10px;

			svg {
				fill: white;
				height: 25px;
				width: 25px;
			}
		}
	}

	.sub-header {
		text-transform: uppercase;
		opacity: 0.6;
		font-size: 0.85rem;
	}

	.total {
		margin-top: -15px;
		color: #78ce63;
		font-size: 3rem;
		font-weight: 600;
	}

	.table-header {
		width: 100%;
		.container {
			display: flex;
			justify-content: space-between;
			padding: 10px 30px;
			opacity: 0.6;
		}
	}

	.table-row {
		width: 100%;

		.container {
			display: flex;
			padding: 7px 30px;
			align-items: center;

			.crest {
				opacity: 0.3;
				height: 30px;
				width: 30px;
				margin-right: 6px;

				img {
					height: 30px;
					max-width: 30px;
				}
			}

			.selected {
				opacity: 1;
			}

			.team-name {
				flex-grow: 1;
			}
		}
	}

	hr {
		border: none;
		border-top: solid lightgrey 1px;
		opacity: 0.1;
		margin: 0 30px;
	}
`;

export const Checkbox = styled.div<{ checked: boolean }>`
	background: #083196;
	height: 25px;
	width: 25px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;

	${({ checked }) =>
		!checked &&
		css`
			background: none;
			border: grey solid 2px;
			opacity: 0.5;
			width: 23px;
			height: 23px;
		`}
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	background: #fe0000;
	color: white;
	margin: 20px 0;
	padding: 10px 90px;
	font-size: 1.25rem;
	font-weight: 600;
	border-radius: 5px;
	cursor: pointer;
`;
