import styled from "styled-components";
import { MID_MOBILE } from "../../static/breakpoints";

export const StyledPage = styled.div`
	position: relative;
	height: 100vh;
	max-height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background: rgba(1, 19, 187, 1);
	background: -moz-radial-gradient(
		bottom,
		ellipse cover,
		rgba(1, 19, 187, 1) 0%,
		rgba(4, 16, 62, 1) 53%,
		rgba(5, 15, 36, 1) 64%
	);
	background: -webkit-gradient(
		radial,
		bottom center,
		0px,
		bottom center,
		100%,
		color-stop(0%, rgba(1, 19, 187, 1)),
		color-stop(53%, rgba(4, 16, 62, 1)),
		color-stop(64%, rgba(5, 15, 36, 1))
	);
	background: -webkit-radial-gradient(
		bottom,
		ellipse cover,
		rgba(1, 19, 187, 1) 0%,
		rgba(4, 16, 62, 1) 53%,
		rgba(5, 15, 36, 1) 64%
	);
	background: -o-radial-gradient(
		bottom,
		ellipse cover,
		rgba(1, 19, 187, 1) 0%,
		rgba(4, 16, 62, 1) 53%,
		rgba(5, 15, 36, 1) 64%
	);
	background: -ms-radial-gradient(
		bottom,
		ellipse cover,
		rgba(1, 19, 187, 1) 0%,
		rgba(4, 16, 62, 1) 53%,
		rgba(5, 15, 36, 1) 64%
	);
	background: radial-gradient(
		ellipse at bottom,
		rgba(1, 19, 187, 1) 0%,
		rgba(4, 16, 62, 1) 53%,
		rgba(5, 15, 36, 1) 64%
	);

	.header {
		background: #04439e;
		height: 70px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;
		text-transform: capitalize;
		color: white;
	}

	.points-container {
		color: white;
		text-align: center;
		font-weight: 900;
		font-style: oblique;
		height: 100px;
		padding-top: 10px;

		.title {
			height: 20px;
		}

		.points {
			font-size: 6rem;
			line-height: 50px;
		}
	}

	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		grid-gap: 30px;
		align-self: center;

		@media (max-width: ${MID_MOBILE}) {
			grid-gap: 20px;
		}
	}

	.prompt {
		height: 150px;
		color: #c6273a;
		text-align: center;
		text-transform: capitalize;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
		font-weight: 900;
		font-style: oblique;
		font-size: 4rem;
		text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff,
			2px 2px 0 #fff;
	}
`;
