import styled from "styled-components";

export const Button = styled.button`
	margin: 5px;
	height: 70px;
	width: 350px;
	color: white;
	background: #003794;
	border: none;
	font-size: 1.5rem;
	text-transform: uppercase;
	font-weight: 900;
	font-style: oblique;
	transition: all 0.1s ease-in-out;

	:active {
		-webkit-box-shadow: inset 0px 0px 20px 0px rgba(255, 255, 255, 1);
		-moz-box-shadow: inset 0px 0px 20px 0px rgba(255, 255, 255, 1);
		box-shadow: inset 0px 0px 20px 0px rgba(255, 255, 255, 1);
	}
`;
