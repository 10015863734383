import React from "react";

interface LandingSquareProps {
	rotation: number;
	fill: string;
}

const LandingSquare: React.FC<LandingSquareProps> = ({ rotation, fill }) => {
	return (
  <svg viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="10"
      y="10"
      width="50"
      height="50"
      rx="3"
      fill={fill}
      transform={`rotate(${rotation}, 35, 35)`}
    />
  </svg>
	);
};

export default LandingSquare;
