import styled, { css } from "styled-components";
import { MID_MOBILE } from "../../static/breakpoints";

interface StyledGridLinesProps {
	completedLines: number[];
}

export const StyledGridLines = styled.div<StyledGridLinesProps>`
	position: absolute;
	height: 387px;
	width: 387px;
	z-index: 0;

	.line {
		height: 380px;
		width: 10px;
		background: red;
		position: absolute;
		background: #063da9;
		transition: all 0.2s ease-in-out;

		@media (max-width: ${MID_MOBILE}) {
			height: 350px;
		}

		:nth-child(1) {
			left: 50px;
			${({ completedLines }) =>
				completedLines[0] &&
				css`
					background: #ffd000;
					-webkit-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					-moz-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
				`}
		}

		:nth-child(2) {
			left: 190px;
			@media (max-width: ${MID_MOBILE}) {
				left: 175px;
			}
			${({ completedLines }) =>
				completedLines[1] &&
				css`
					background: #ffd000;
					-webkit-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					-moz-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
				`}
		}

		:nth-child(3) {
			left: 330px;
			@media (max-width: ${MID_MOBILE}) {
				left: 300px;
			}

			${({ completedLines }) =>
				completedLines[2] &&
				css`
					background: #ffd000;
					-webkit-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					-moz-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
				`}
		}

		:nth-child(4) {
			top: -140px;
			@media (max-width: ${MID_MOBILE}) {
				top: -120px;
			}
			${({ completedLines }) =>
				completedLines[3] &&
				css`
					background: #ffd000;
					-webkit-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					-moz-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
				`}
		}

		:nth-child(5) {
			@media (max-width: ${MID_MOBILE}) {
				top: 5px;
			}
			${({ completedLines }) =>
				completedLines[4] &&
				css`
					background: #ffd000;
					-webkit-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					-moz-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
				`}
		}

		:nth-child(6) {
			top: 140px;
			@media (max-width: ${MID_MOBILE}) {
				top: 130px;
			}
			${({ completedLines }) =>
				completedLines[5] &&
				css`
					background: #ffd000;
					-webkit-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					-moz-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
				`}
		}

		:nth-child(7) {
			left: 188px;
			@media (max-width: ${MID_MOBILE}) {
				left: 180px;
			}
			transform: rotate(45deg);
			${({ completedLines }) =>
				completedLines[6] &&
				css`
					background: #ffd000;
					-webkit-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					-moz-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
				`}
		}

		:nth-child(8) {
			left: 188px;
			@media (max-width: ${MID_MOBILE}) {
				left: 170px;
			}
			transform: rotate(-45deg);
			${({ completedLines }) =>
				completedLines[7] &&
				css`
					background: #ffd000;
					-webkit-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					-moz-box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
					box-shadow: 0px 0px 15px 0px rgba(255, 208, 0, 0.6);
				`}
		}
	}

	.horizontal {
		transform: rotate(90deg);
		left: 190px;

		@media (max-width: ${MID_MOBILE}) {
			left: 170px;
		}
	}
`;
