const fixtures =
	process.env.REACT_APP_GAMETYPE === "soccer"
		? [
				{
					id: 1,
					home: "ARS",
					away: "WOL",
					pointsHome: 10,
					pointsAway: 20,
					predicted: false,
					status: "draw"
				},
				{
					id: 2,
					home: "AVL",
					away: "WHU",
					pointsHome: 50,
					pointsAway: 20,
					predicted: false,
					status: "draw"
				},
				{
					id: 3,
					home: "BHA",
					away: "WAT",
					pointsHome: 10,
					pointsAway: 40,
					predicted: false,
					status: "draw"
				},
				{
					id: 4,
					home: "BOU",
					away: "TOT",
					pointsHome: 30,
					pointsAway: 50,
					predicted: false,
					status: "draw"
				},
				{
					id: 5,
					home: "BUR",
					away: "SOU",
					pointsHome: 20,
					pointsAway: 40,
					predicted: false,
					status: "draw"
				},
				{
					id: 6,
					home: "CHE",
					away: "SHF",
					pointsHome: 20,
					pointsAway: 20,
					predicted: false,
					status: "draw"
				},
				{
					id: 7,
					home: "EVE",
					away: "PAL",
					pointsHome: 10,
					pointsAway: 40,
					predicted: false,
					status: "draw"
				},
				{
					id: 8,
					home: "LEI",
					away: "NOR",
					pointsHome: 50,
					pointsAway: 10,
					predicted: false,
					status: "draw"
				},
				{
					id: 9,
					home: "LIV",
					away: "NEW",
					pointsHome: 30,
					pointsAway: 20,
					predicted: false,
					status: "draw"
				},
				{
					id: 10,
					home: "MCI",
					away: "MUN",
					pointsHome: 30,
					pointsAway: 20,
					predicted: false,
					status: "draw"
				}
		  ]
		: [
				{
					id: 1,
					home: "ARI",
					away: "ATL",
					pointsHome: 10,
					pointsAway: 20,
					predicted: false,
					status: "draw"
				},
				{
					id: 2,
					home: "BAL",
					away: "WAS",
					pointsHome: 50,
					pointsAway: 20,
					predicted: false,
					status: "draw"
				},
				{
					id: 3,
					home: "BUF",
					away: "TEN",
					pointsHome: 10,
					pointsAway: 40,
					predicted: false,
					status: "draw"
				},
				{
					id: 4,
					home: "CAR",
					away: "TB",
					pointsHome: 30,
					pointsAway: 50,
					predicted: false,
					status: "draw"
				},
				{
					id: 5,
					home: "CHI",
					away: "SF",
					pointsHome: 20,
					pointsAway: 40,
					predicted: false,
					status: "draw"
				},
				{
					id: 6,
					home: "CIN",
					away: "SEA",
					pointsHome: 20,
					pointsAway: 20,
					predicted: false,
					status: "draw"
				},
				{
					id: 7,
					home: "CLE",
					away: "PIT",
					pointsHome: 10,
					pointsAway: 40,
					predicted: false,
					status: "draw"
				},
				{
					id: 8,
					home: "PHI",
					away: "DAL",
					pointsHome: 50,
					pointsAway: 10,
					predicted: false,
					status: "draw"
				},
				{
					id: 9,
					home: "OAK",
					away: "DEN",
					pointsHome: 30,
					pointsAway: 20,
					predicted: false,
					status: "draw"
				}
		  ];

module.exports = fixtures;
