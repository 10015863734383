import styled from "styled-components";

export const StyledMenuBar = styled.div`
	width: 100%;
	background: blue;
	background-image: linear-gradient(#0252c6, #03347e);
	color: white;
	display: flex;
	height: 75px;
	border-top: cyan 1px solid;
	justify-content: space-evenly;
	text-transform: capitalize;
	align-items: center;

	* {
		font-family: Arial, Helvetica, sans-serif;
	}

	.item {
		display: flex;
		flex-direction: column;
		align-items: center;

		span {
			font-size: 0.8rem;
			margin-top: 5px;
		}

		svg {
			height: 25px;
			width: 25px;
		}
	}

	.selected {
		color: #009ffd;
	}
`;
