import React, { useEffect, useState } from "react";

import { FaPlus } from "react-icons/fa";
import { MdClose, MdSwapHoriz } from "react-icons/md";
import { StyledGridItem } from "./style";
import { useSelectionContext } from "../../contexts/SelectionContext";

interface GridItemProps {
	click?(): void;
	fixture?: {
		id?: number;
		chosen?: string;
		chosenPoints?: number;
		home?: string;
		away?: string;
		pointsHome?: number;
		pointsAway?: number;
	};
	index?: number;
	removable: boolean;
}

const GridItem: React.FC<GridItemProps> = ({
	removable,
	fixture,
	click,
	index
}) => {
	const {
		selections,
		removeSelectionByIndex,
		swapItem
	} = useSelectionContext();

	const [gold, setGold] = useState(false);
	const [swapState, setSwapState] = useState(false);
	const [winningSide, setWinningSide] = useState();

	useEffect(() => {
		if (selections && fixture) {
			const fixtureIndex = selections.findIndex(s => s.id === fixture.id);
			if (typeof fixtureIndex !== "undefined" && fixtureIndex >= 0) {
				if (selections[fixtureIndex].status) {
					setWinningSide(selections[fixtureIndex].status);
				}
				if (
					selections[fixtureIndex].status === "home" &&
					fixture.chosen === fixture.home
				) {
					setGold(true);
				} else if (
					selections[fixtureIndex].status === "away" &&
					fixture.chosen === fixture.away
				) {
					setGold(true);
				} else {
					setGold(false);
				}
			}
		}
		setSwapState(false);
	}, [fixture, selections]);

	return (
  <StyledGridItem
    onClick={click}
    gold={gold}
    swap={swapState}
    winningSide={winningSide}
    role="button"
		>
    {fixture && fixture.chosen ? (
      <>
        {removable && (
        <>
          <button
            type="button"
            onClick={e => {
									e.stopPropagation();
									if (
										swapItem &&
										typeof index === "number" &&
										index >= 0
									) {
										swapItem(index);
										setSwapState(!swapState);
									}
								}}
          >
            <MdSwapHoriz title="Swap" />
          </button>
          <button
            type="button"
            onClick={e => {
									e.stopPropagation();

									if (
										removeSelectionByIndex &&
										typeof index === "number" &&
										index >= 0
									) {
										removeSelectionByIndex(index);
									}
								}}
          >
            <MdClose title="Remove" />
          </button>
        </>
					)}
        <div className="fixture-container">
          <img
            src={
								process.env.REACT_APP_GAMETYPE === "soccer"
									? `/images/team-logos-soccer/${fixture &&
											fixture.away &&
											fixture.away}.svg`
									: `/images/team-logos/${fixture &&
											fixture.away &&
											fixture.away}.svg`
							}
            alt={fixture && fixture.away && fixture.away}
            className="icon"
          />
          <div className="at">
            {process.env.REACT_APP_GAMETYPE === "soccer"
								? "vs"
								: "at"}
          </div>
          <img
            src={
								process.env.REACT_APP_GAMETYPE === "soccer"
									? `/images/team-logos-soccer/${fixture &&
											fixture.home &&
											fixture.home}.svg`
									: `/images/team-logos/${fixture &&
											fixture.home &&
											fixture.home}.svg`
							}
            alt={fixture && fixture.home && fixture.home}
            className="icon"
          />
        </div>
        <div className="selection bold-italic-upper">
          {fixture.chosen}
        </div>
      </>
			) : (
  <div className="empty">{removable && <FaPlus />}</div>
			)}
  </StyledGridItem>
	);
};

export default GridItem;
