import React from "react";

import { Link } from "react-router-dom";
import { StyledPage } from "./style";
import { Button } from "../../styles/Elements";
import LandingSquare from "../../components/svgs/LandingSquare";

const Landing: React.FC = () => (
  <StyledPage>
    <div className="container">
      <div className="how-to">
        <p>how to play</p>
      </div>
      <div className="company-logo">onSport</div>
      <div className="header">
        <LandingSquare rotation={20} fill="#083BA6" />
        <LandingSquare rotation={8} fill="#F38307" />
        <LandingSquare rotation={-7} fill="#003794" />
        <div className="bold-italic-upper title">
					golden 
          {' '}
          <span>grid</span>
        </div>
      </div>
      <div className="prompt bold-italic-upper">
        <p>
					can you
          <span> conquer the </span>
					grid 
          {' '}
          <span className="big">?</span>
        </p>
      </div>
      <div className="btn-container">
        <Link to="/build">
          <Button type="button" className="bold-italic-upper">
						play
          </Button>
        </Link>
      </div>
    </div>
  </StyledPage>
);
export default Landing;
