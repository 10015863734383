import React, { useState, useEffect, useContext } from "react";
import { ModalContext } from "./ModalContext";
import { FixtureContext } from "./FixtureContext";
import {
	StaticFixtureType,
	FixtureType
} from "../interfaces/fixture-interface";
import {
	SelectionContextInterface,
	SelectionContextProviderProps
} from "../interfaces/selection-interface";
import { WebSocketContext } from "./WebSocketContext";

const noSelections = [{}, {}, {}, {}, {}, {}, {}, {}, {}];

export const SelectionContext = React.createContext<
	SelectionContextInterface | undefined
>(undefined);

export const SelectionContextProvider: React.FC<
	SelectionContextProviderProps
> = props => {
	const { isModalOpen, animation } = useContext(ModalContext);
	const { fixtures, setFixtures } = useContext(FixtureContext);
	const { socket } = useContext(WebSocketContext);

	const [editing, setEditing] = useState(-1);
	const [full, setFull] = useState(false);
	const [selections, setSelections] = useState<FixtureType[]>(noSelections);
	const [swapIndex, setSwapIndex] = useState(-1);

	const swapItem = (index: number) => {
		if (swapIndex >= 0) {
			setSelections(prevSelections => {
				const nextSelections = [...prevSelections];
				[nextSelections[swapIndex], nextSelections[index]] = [
					nextSelections[index],
					nextSelections[swapIndex]
				];
				return nextSelections;
			});
			setSwapIndex(-1);
		} else {
			setSwapIndex(index);
		}
	};

	const setStatusByFixtureId = (event: {
		fixtureId: number;
		event: string;
	}) => {
		if (event) {
			setSelections(prevSelections => {
				const nextSelections = [...prevSelections];
				const index = nextSelections.findIndex(
					x => x.id === event.fixtureId
				);
				if (index >= 0) {
					nextSelections[index].status = event.event;
				}
				return nextSelections;
			});
		}
	};

	const reset = () => setSelections(noSelections);

	const setSelectionByIndex = (
		chosen: string,
		chosenPoints: number,
		fixture: FixtureType
	) => {
		setSelections(prevSelections => {
			const nextSelections = [...prevSelections];
			nextSelections[editing] = { ...fixture, chosen, chosenPoints };
			return nextSelections;
		});
	};

	const removeSelectionByIndex = (index: number) => {
		setSelections(prevSelections => {
			const nextSelections = [...prevSelections];
			nextSelections[index] = {};
			return nextSelections;
		});
	};

	useEffect(() => {
		if (socket) {
			socket.on("event", (res: { fixtureId: number; event: string }) =>
				setStatusByFixtureId(res)
			);
		}
	}, [socket]);

	useEffect(() => {
		if (setFixtures)
			setFixtures(prevFixtures => {
				const nextFixtures = [...prevFixtures];
				if (editing >= 0) {
					const editingIndex = nextFixtures.findIndex(
						(x: StaticFixtureType) =>
							x.id === selections[editing].id
					);
					if (nextFixtures[editingIndex]) {
						nextFixtures[editingIndex].predicted = false;
					}
				}

				return nextFixtures;
			});
	}, [editing, isModalOpen, animation, selections, setFixtures]);

	useEffect(() => {
		selections.forEach(selection => {
			if (selection.id && fixtures && setFixtures) {
				const fixtureSelected = fixtures
					.filter(f => f.id && f.id === selection.id)
					.map(x => x.id);
				if (fixtureSelected && fixtureSelected.length > 0) {
					setFixtures(prevFixtures => {
						prevFixtures.forEach(x => {
							x.predicted = false;
							return x;
						});
						selections.forEach(s => {
							const index = prevFixtures.findIndex(
								y => y.id === s.id
							);
							if (index >= 0 && prevFixtures[index])
								prevFixtures[index].predicted = true;
						});
						return prevFixtures;
					});
				}
			}
		});
	}, [fixtures, selections, setFixtures]);

	useEffect(() => {
		setFull(
			selections.every(s =>
				Object.prototype.hasOwnProperty.call(s, "chosen")
			)
		);
		setSwapIndex(-1);
	}, [selections]);

	return (
		<SelectionContext.Provider
			value={{
				setEditing,
				selections,
				setSelectionByIndex,
				removeSelectionByIndex,
				setStatusByFixtureId,
				full,
				reset,
				swapItem
			}}
			{...props}
		/>
	);
};

export const useSelectionContext = () => {
	const context = React.useContext(SelectionContext);
	if (context === undefined) {
		throw new Error(
			"useSelectionContext must be used within a SelectionProvider"
		);
	}
	return context;
};
