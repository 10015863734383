import React, { createContext, ReactNode, useState, useEffect } from "react";
import io from "socket.io-client";

interface WebSocketContextInterface {
	socket?: SocketIOClient.Socket;
}

interface WebSocketContextProviderInterface {
	value?: WebSocketContextInterface;
	children: ReactNode;
}

export const WebSocketContext = createContext<WebSocketContextInterface>({});

export const WebSocketContextProvider: React.FC<
	WebSocketContextProviderInterface
> = ({ children }) => {
	const [socket, setSocket] = useState();

	useEffect(() => {
		const url =
			process.env.NODE_ENV === "development"
				? "http://localhost:3011"
				: "https://grid-events.herokuapp.com/";
		setSocket(io(url));
	}, []);

	return (
  <WebSocketContext.Provider value={{ socket }}>
    {children}
  </WebSocketContext.Provider>
	);
};
