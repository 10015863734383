import React, { useContext, useState, useEffect } from "react";
import { MdClose } from "react-icons/md";

import Fixture from "../Fixture";

import { StyledModal } from "./style";
import { useSelectionContext } from "../../contexts/SelectionContext";
import { ModalContext } from "../../contexts/ModalContext";
import { FixtureContext } from "../../contexts/FixtureContext";
import { FixtureType } from "../../interfaces/fixture-interface";

const Modal: React.FC = () => {
	const { setSelectionByIndex } = useSelectionContext();
	const { animation, setAnimation } = useContext(ModalContext);
	const { fixtures } = useContext(FixtureContext);

	return (
  <StyledModal animation={animation}>
    <div className="top-bar">
      <button
        type="button"
        className="close"
        onClick={() => setAnimation && setAnimation("close")}
        data-testid="close"
      >
        <MdClose />
      </button>
      <div className="title">select a fixture</div>
      <div className="grid-position" />
    </div>
    <div className="fixtures">
      {fixtures &&
					fixtures
						.filter(x => x.predicted === false)
						.map((fixture: FixtureType) => (
  <Fixture
    home={fixture.home}
    away={fixture.away}
    pointsHome={fixture.pointsHome}
    pointsAway={fixture.pointsAway}
    click={(
									chosen: string,
									chosenPoints: number
								) => {
									if (setSelectionByIndex && setAnimation) {
										setAnimation("close");
										setSelectionByIndex(
											chosen,
											chosenPoints,
											fixture
										);
									}
								}}
  />
						))}
    </div>
  </StyledModal>
	);
};

export default Modal;
