import React from "react";
import { FaTrophy, FaInfoCircle, FaWrench, FaTh } from "react-icons/fa";
import { StyledMenuBar } from "./style";

const MenuBar: React.FC = () => {
	return (
  <StyledMenuBar>
    <div className="item selected">
      <FaTh />
      <span>my grids</span>
    </div>
    <div className="item">
      <FaTrophy />
      <span>leaderboard</span>
    </div>
    <div className="item">
      <FaInfoCircle />
      <span>info</span>
    </div>
    <div className="item">
      <FaWrench />
      <span>settings</span>
    </div>
  </StyledMenuBar>
	);
};

export default MenuBar;
