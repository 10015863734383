import React, { useContext, useEffect } from "react";

import { Link } from "react-router-dom";
import GridItem from "../../components/GridItem";
import GridLines from "../../components/GridLines";
import Modal from "../../components/Modal";
import MenuBar from "../../components/MenuBar";

import { StyledPage } from "./style";
import { Button } from "../../styles/Elements";
import { useSelectionContext } from "../../contexts/SelectionContext";
import { ModalContext } from "../../contexts/ModalContext";

const Build: React.FC = () => {
	const { selections, full, setEditing } = useSelectionContext();
	const { setAnimation } = useContext(ModalContext);

	return (
  <StyledPage>
    <div className="header">
      <div className="gw">gw 1</div>
      <div className="state">build your grid</div>
      <div className="help">help</div>
    </div>
    <div className="points-container">
      <div className="title" />
      <div className="points" />
    </div>
    <div className="grid">
      {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(i => (
        <GridItem
          click={() => {
							if (setEditing && setAnimation) {
								setEditing(i);
								setAnimation("open");
							}
						}}
          fixture={selections && selections[i]}
          index={i}
          removable
        />
				))}
      <GridLines />
    </div>
    <div className="prompt">
      {full ? (
        <Link to="bet">
          <Button type="button">Confirm Grid</Button>
        </Link>
				) : (
					"click the swap button to adjust your selections"
				)}
    </div>
    <MenuBar />
    <Modal />
  </StyledPage>
	);
};

export default Build;
