import React, { useContext } from "react";
import { StyledGridLines } from "./style";
import { useGridLines } from "../../hooks/useGridLines";
import { useSelectionContext } from "../../contexts/SelectionContext";

const GridLines: React.FC = () => {
	const { selections } = useSelectionContext();
	const { completedLines } = useGridLines(selections);
	return (
  <StyledGridLines completedLines={completedLines}>
    <div className="line" />
    <div className="line" />
    <div className="line" />
    <div className="line horizontal" />
    <div className="line horizontal" />
    <div className="line horizontal" />
    <div className="line diagonal" />
    <div className="line diagonal" />
  </StyledGridLines>
	);
};

export default GridLines;
