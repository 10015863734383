import styled, { css } from "styled-components";
import { MID_MOBILE } from "../../static/breakpoints";

interface StyledGridItemProps {
	gold?: boolean;
	swap?: boolean;
	winningSide?: string;
}

export const StyledGridItem = styled.div<StyledGridItemProps>`
	position: relative;
	background: #0a2385;
	height: 105px;
	width: 105px;
	border-radius: 2px;
	border-width: 3px;
	border-style: solid;
	border-color: ${({ gold, swap }) => {
		if (gold) return "yellow";
		if (swap) return "green";
		return "rgba(255, 255, 255, 0.15)";
	}};
	z-index: 1;
	transition: all 0.2s ease-in-out;

	@media (max-width: ${MID_MOBILE}) {
		height: 100px;
		width: 100px;
	}

	${({ swap }) =>
		swap
			? css`
					-webkit-box-shadow: inset 0px 0px 8px 0px
						rgba(0, 255, 0, 0.3);
					-moz-box-shadow: inset 0px 0px 8px 0px rgba(0, 255, 0, 0.3);
					box-shadow: inset 0px 0px 8px 0px rgba(0, 255, 0, 0.3);
			  `
			: css`
					-webkit-box-shadow: inset 0px 0px 8px 0px
						rgba(255, 255, 255, 0.3);
					-moz-box-shadow: inset 0px 0px 8px 0px
						rgba(255, 255, 255, 0.3);
					box-shadow: inset 0px 0px 8px 0px rgba(255, 255, 255, 0.3);
			  `}

	.empty {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			fill: rgba(255, 255, 255, 0.2);
			height: 50px;
			width: 50px;
		}
	}

	button {
		position: absolute;
		bottom: -15px;
		right: -10px;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		border: none;
		background: red;
		color: white;
		outline: none;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			height: 18px;
			width: 18px;
		}

		:first-child {
			right: 30px;
			background: green;
		}

		:active {
			-webkit-box-shadow: inset 0px 0px 8px 0px rgba(255, 255, 255, 0.3);
			-moz-box-shadow: inset 0px 0px 8px 0px rgba(255, 255, 255, 0.3);
			box-shadow: inset 0px 0px 8px 0px rgba(255, 255, 255, 0.3);
		}
	}

	.fixture-container {
		height: 50px;
		display: flex;
		justify-content: space-between;
		padding: 0 3px;
		align-items: center;
		color: white;

		.icon {
			height: 38px;
			width: 38px;
		}
		.icon:first-child {
			opacity: ${({ winningSide }) => winningSide === "home" && "0.5"};
		}
		.icon:nth-child(3) {
			opacity: ${({ winningSide }) => winningSide === "away" && "0.5"};
		}
	}

	.selection {
		height: 31px;
		text-align: center;
		color: white;
		font-size: 1.6rem;
	}

	hr {
		border: none;
		border-top: #0369cc solid 4px;
	}

	.points {
		color: white;
		font-size: 1.2rem;
		padding-left: 8px;
		margin-top: -2px;
		span {
			font-size: 0.7rem;
		}
		background: #0a2385; /* Old browsers */
		background: -moz-linear-gradient(
			left,
			#0a2385 1%,
			#0546b4 50%,
			#0a2385 100%
		); /* FF3.6-15 */
		background: -webkit-linear-gradient(
			left,
			#0a2385 1%,
			#0546b4 50%,
			#0a2385 100%
		); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(
			to right,
			#0a2385 1%,
			#0546b4 50%,
			#0a2385 100%
		); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}

	.bold-italic-upper {
		text-transform: uppercase;
		font-weight: 900;
		font-style: oblique;
	}
`;
