import React, { useState } from "react";

import fixturesStatic from "../static/fixtures";
import {
	FixtureContextInterface,
	FixtureContextProviderProps
} from "../interfaces/fixture-interface";

export const FixtureContext = React.createContext<FixtureContextInterface>({});

export const FixtureContextProvider: React.FC<FixtureContextProviderProps> = ({
	children
}) => {
	const [fixtures, setFixtures] = useState(fixturesStatic);

	return (
  <FixtureContext.Provider value={{ fixtures, setFixtures }}>
    {children}
  </FixtureContext.Provider>
	);
};
