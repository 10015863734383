import React, {
	useState,
	SetStateAction,
	Dispatch,
	ReactNode,
	useEffect
} from "react";

interface ModalContextInterface {
	isModalOpen?: boolean;
	animation?: string;
	setIsModalOpen?: Dispatch<SetStateAction<boolean>>;
	setAnimation?: Dispatch<SetStateAction<string>>;
}

interface ModalContextProviderProps {
	children: ReactNode;
	value?: ModalContextInterface;
}

export const ModalContext = React.createContext<ModalContextInterface>({});

export const ModalContextProvider: React.FC<ModalContextProviderProps> = ({
	children
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [animation, setAnimation] = useState("");

	useEffect(() => {
		if (animation === "open") setIsModalOpen(true);
		else if (animation === "close") setIsModalOpen(false);
	}, [animation]);

	return (
  <ModalContext.Provider
    value={{ isModalOpen, setIsModalOpen, setAnimation, animation }}
		>
    {children}
  </ModalContext.Provider>
	);
};
