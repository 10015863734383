import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { useSelectionContext } from "../../contexts/SelectionContext";
import { StyledPage, StyledLink, Checkbox } from "./style";

const Bet: React.FC = () => {
	const { selections } = useSelectionContext();
	const [checked, setChecked] = useState([
		true,
		true,
		true,
		true,
		true,
		true,
		true,
		true,
		true
	]);

	return (
  <StyledPage>
    <div className="header">
      <p>A $10 Accumulator</p>
      <Link to="/play">
        <MdClose />
      </Link>
    </div>
    <div className="sub-header">Would return...</div>
    <div className="total">$9,491.20</div>
    <div className="table-header">
      <div className="container">
        <div className="header-item">your prediction</div>
        <div className="header-item">include?</div>
      </div>
    </div>
    {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(i => {
				if (selections && selections[i].chosen) {
					const { chosen, home, away } = selections[i];
					return (
  <div className="table-row">
    <div className="container">
      <div
        className={`crest ${chosen === home &&
										"selected"}`}
      >
        <img
          src={
											process.env.REACT_APP_GAMETYPE ===
											"soccer"
												? `/images/team-logos-soccer/${home}.svg`
												: `/images/team-logos/${home}.svg`
										}
          alt="home"
          className="icon"
        />
      </div>
      <div
        className={`crest ${chosen === away &&
										"selected"}`}
      >
        <img
          src={
											process.env.REACT_APP_GAMETYPE ===
											"soccer"
												? `/images/team-logos-soccer/${away}.svg`
												: `/images/team-logos/${away}.svg`
										}
          alt="away"
          className="icon"
        />
      </div>
      <div className="team-name">
        {selections && selections[i].chosen}
      </div>
      <Checkbox
        checked={checked[i]}
        onClick={() =>
										setChecked(prevChecked => {
											const cp = [...prevChecked];
											cp[i] = !prevChecked[i];
											return cp;
										})
									}
      >
        {checked[i] && <FaCheck />}
      </Checkbox>
    </div>
    <hr />
  </div>
					);
				}
				return null;
			})}
    <StyledLink to="/play">place my bet now</StyledLink>
  </StyledPage>
	);
};

export default Bet;
