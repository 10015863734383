import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { SelectionContextProvider } from "./contexts/SelectionContext";
import { ModalContextProvider } from "./contexts/ModalContext";
import { FixtureContextProvider } from "./contexts/FixtureContext";
import { WebSocketContextProvider } from "./contexts/WebSocketContext";

import Landing from "./pages/landing";
import Build from "./pages/build";
import Play from "./pages/play";
import Bet from "./pages/bet";

const App: React.FC = () => {
	return (
  <Router>
    <div>
      <Route exact path="/" component={Landing} />
      <WebSocketContextProvider>
        <FixtureContextProvider>
          <ModalContextProvider>
            <SelectionContextProvider>
              <Route path="/build" component={Build} />
              <Route path="/bet" component={Bet} />
              <Route path="/play" component={Play} />
            </SelectionContextProvider>
          </ModalContextProvider>
        </FixtureContextProvider>
      </WebSocketContextProvider>
    </div>
  </Router>
	);
};

export default App;
