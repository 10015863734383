import styled from "styled-components";

export const StyledPage = styled.div`
	background: #030d1e;
	height: 100vh;

	.container {
		text-align: center;
		color: white;
		display: flex;
		flex-direction: column;

		.how-to {
			height: 45px;
			display: flex;
			align-items: flex-end;

			p {
				margin-left: auto;
				margin-right: 35px;
				text-transform: capitalize;
				font-size: 0.9rem;
				opacity: 0.75;
			}
		}

		.company-logo {
			height: 70px;
			font-weight: bolder;
			font-size: 2.5rem;
		}

		.header {
			height: 265px;
			font-size: 5rem;
			line-height: 90px;

			.title {
				position: relative;
				z-index: 1;
			}

			svg {
				position: absolute;
				height: 300px;
				width: 300px;
				z-index: 0;
				left: 50px;
				top: 70px;
			}

			span {
				font-size: 8.5rem;
			}
		}

		.prompt {
			display: flex;
			justify-content: center;
			height: 150px;

			p {
				font-size: 2.5rem;
				max-width: 200px;
				text-align: center;
				line-height: 29px;

				span {
					font-size: 1.75rem;
				}
				.big {
					font-size: 3.5rem;
				}
			}
		}

		.btn-container {
			display: flex;
			flex-direction: column;
			padding: 5px;
			align-items: center;
		}
	}

	.bold-italic-upper {
		text-transform: uppercase;
		font-weight: 900;
		font-style: oblique;
	}
`;
