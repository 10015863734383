import { useEffect, useState } from "react";
import { FixtureType } from "../interfaces/fixture-interface";

const noCompletedLines = [0, 0, 0, 0, 0, 0, 0, 0, 0];

const completedLineMap: number[][][] = [
	[[0, 1, 2], [3]],
	[[3, 4, 5], [4]],
	[[6, 7, 8], [5]],
	[[0, 3, 6], [0]],
	[[1, 4, 7], [1]],
	[[2, 5, 8], [2]],
	[[0, 4, 8], [7]],
	[[2, 4, 6], [8]]
];

export const useGridLines = (selections?: FixtureType[]) => {
	const [completedLines, setCompletedLines] = useState(noCompletedLines);

	const isCorrectPrediction = (selection: FixtureType) => {
		if (selection) {
			if (
				selection.chosen === selection.away &&
				selection.status === "away"
			)
				return true;
			if (
				selection.chosen === selection.home &&
				selection.status === "home"
			)
				return true;
		}
		return false;
	};

	useEffect(() => {
		setCompletedLines(noCompletedLines);
		if (selections) {
			for (let i = 0; i < completedLineMap.length; i += 1) {
				if (
					completedLineMap[i][0]
						.map(j => selections[j])
						.every(isCorrectPrediction)
				) {
					setCompletedLines(prevCompletedLines => {
						const nextCompletedLines = [...prevCompletedLines];
						nextCompletedLines[completedLineMap[i][1][0]] = 1;
						return nextCompletedLines;
					});
				}
			}
		}
	}, [selections]);

	return { completedLines };
};
