import styled, { keyframes, css } from "styled-components";

const openAnim = keyframes`
	  from {
		transform: translateY(0);
	  }
	
	  to {
		transform: translateY(-100vh);
	  }
	`;

const closeAnim = keyframes`
	  from {
		transform: translateY(-100vh);
	  }
	
	  to {
		transform: translateY(0);
	  }
	`;

interface StyledModalProps {
	open?: boolean;
	animation?: string;
}

export const StyledModal = styled.div<StyledModalProps>`
	position: absolute;
	z-index: 2;
	height: 100vh;
	width: 100vw;
	top: 100vh;
	background: #081d41;
	animation: ${({ animation }) => {
		if (animation === "open")
			return css`
				${openAnim} 0.25s ease-in-out
			`;
		if (animation === "close")
			return css`
				${closeAnim} 0.25s ease-in-out
			`;
		return "none";
	}};

	animation-fill-mode: forwards;

	.top-bar {
		display: flex;
		color: white;
		justify-content: space-between;
		align-items: center;
		height: 70px;

		.close {
			color: white;
			background: none;
			outline: none;
			border: none;
			font-size: 1.25rem;
			padding-left: 20px;
		}
	}

	.fixtures {
		padding: 0 10px;
		overflow-y: auto;
		height: 600px;
	}
`;
